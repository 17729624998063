<template>
  <div class="controls row">
    <div class="controls__channels col-3 d-flex flex-column border-right">
      <span class="text-uppercase"><strong> Каналы </strong></span>
      <div class="d-flex">
        <label for="select_category" class="mr-2 small text-overflow"
          >Категория:</label
        >
        <select class="controls__select" id="select_category">
          <option class="controls__option">asdfasf</option>
          <option class="controls__option"
            >123123123123123123212412341234</option
          >
        </select>
      </div>
      <div class="d-flex mt-2">
        <label for="sub_filter" class="mr-2 small text-overflow"
          >Кол-во подписчиков:</label
        >
        <select class="controls__select" id="sub_filter">
          <option class="controls__option">По возрастанию</option>
          <option class="controls__option">По убыванию</option>
        </select>
      </div>
    </div>
    <div class="controls__posts col-6" style="max-height: 110px">
      <div class="d-flex justify-content-between row">
        <div class="d-flex flex-column col-6">
          <div class="d-flex small mb-1">
            <i class="i-heart mr-1" />
            от <input type="text" class="controls__input mx-2" /> до
            <input type="text" class="controls__input ml-2" />
          </div>
          <div class="d-flex small mb-1">
            <i class="i-eye mr-1" />
            от <input type="text" class="controls__input mx-2" /> до
            <input type="text" class="controls__input ml-2" />
          </div>
          <div class="d-flex small mb-1">
            <i class="i-share mr-1" />
            от <input type="text" class="controls__input mx-2" /> до
            <input type="text" class="controls__input ml-2" />
          </div>
          <div class="d-flex small">
            <i class="i-calendar mr-1" />
            от <input type="text" class="controls__input mx-2" /> до
            <input type="text" class="controls__input ml-2" />
          </div>
        </div>
        <div class="d-flex flex-column justify-content-start col-6">
          <span>Сортировка</span>
          <div style="flex-grow: 1"></div>
          <div class="d-flex justify-content-between mb-1">
            <label for="likes_filter" class="mr-2 small text-overflow"
              >По лайкам:</label
            >
            <select class="controls__filter" id="likes_filter">
              <option class="controls__option">По возрастанию</option>
              <option class="controls__option">По убыванию</option>
            </select>
          </div>
          <div class="d-flex justify-content-between mb-1">
            <label for="views_filter" class="mr-2 small text-overflow"
              >По просмотрам:</label
            >
            <select class="controls__filter" id="views_filter">
              <option class="controls__option">По возрастанию</option>
              <option class="controls__option">По убыванию</option>
            </select>
          </div>
          <div class="d-flex justify-content-between">
            <label for="share_filter" class="mr-2 small text-overflow"
              >По шарингам:</label
            >
            <select class="controls__filter" id="share_filter">
              <option class="controls__option">По возрастанию</option>
              <option class="controls__option">По убыванию</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="controls__view-mode col-3 d-flex">
      <mega-radio
        :column="true"
        :start="true"
        :label="'Режим просмотра'"
        :placeholder="'Режим просмотра'"
        :options="[
          { name: 'Редактирование', value: 1 },
          { name: 'Лента', value: 2 }
        ]"
        class="small"
        v-model="round_type"
        :current="round_type"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Controls"
};
</script>

<style lang="sass" scoped>
.controls
  &__select
    width: 100%
  &__input
    max-width: 4rem
  &__filter
    max-width: 7rem
    font-size: 0.7rem
</style>
